exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gatsbyjs-how-to-install-gatsby-on-mac-js": () => import("./../../../src/pages/gatsbyjs/how-to-install-gatsby-on-mac.js" /* webpackChunkName: "component---src-pages-gatsbyjs-how-to-install-gatsby-on-mac-js" */),
  "component---src-pages-gatsbyjs-how-to-use-css-module-js": () => import("./../../../src/pages/gatsbyjs/how-to-use-css-module.js" /* webpackChunkName: "component---src-pages-gatsbyjs-how-to-use-css-module-js" */),
  "component---src-pages-gatsbyjs-how-to-use-scss-sass-js": () => import("./../../../src/pages/gatsbyjs/how-to-use-scss-sass.js" /* webpackChunkName: "component---src-pages-gatsbyjs-how-to-use-scss-sass-js" */),
  "component---src-pages-gatsbyjs-index-js": () => import("./../../../src/pages/gatsbyjs/index.js" /* webpackChunkName: "component---src-pages-gatsbyjs-index-js" */),
  "component---src-pages-gatsbyjs-what-is-gatsbyjs-js": () => import("./../../../src/pages/gatsbyjs/what-is-gatsbyjs.js" /* webpackChunkName: "component---src-pages-gatsbyjs-what-is-gatsbyjs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-odoo-dashboard-create-odoo-custom-dashboard-js": () => import("./../../../src/pages/odoo/dashboard/create-odoo-custom-dashboard.js" /* webpackChunkName: "component---src-pages-odoo-dashboard-create-odoo-custom-dashboard-js" */),
  "component---src-pages-odoo-dashboard-index-js": () => import("./../../../src/pages/odoo/dashboard/index.js" /* webpackChunkName: "component---src-pages-odoo-dashboard-index-js" */),
  "component---src-pages-odoo-dashboard-odoo-dashboard-using-chartjs-js": () => import("./../../../src/pages/odoo/dashboard/odoo-dashboard-using-chartjs.js" /* webpackChunkName: "component---src-pages-odoo-dashboard-odoo-dashboard-using-chartjs-js" */),
  "component---src-pages-odoo-dashboard-odoo-dashboard-using-owl-action-service-js": () => import("./../../../src/pages/odoo/dashboard/odoo-dashboard-using-owl-action-service.js" /* webpackChunkName: "component---src-pages-odoo-dashboard-odoo-dashboard-using-owl-action-service-js" */),
  "component---src-pages-odoo-datetime-picker-using-owl-js": () => import("./../../../src/pages/odoo/datetime-picker-using-owl.js" /* webpackChunkName: "component---src-pages-odoo-datetime-picker-using-owl-js" */),
  "component---src-pages-odoo-index-js": () => import("./../../../src/pages/odoo/index.js" /* webpackChunkName: "component---src-pages-odoo-index-js" */),
  "component---src-pages-odoo-owl-index-js": () => import("./../../../src/pages/odoo/owl/index.js" /* webpackChunkName: "component---src-pages-odoo-owl-index-js" */),
  "component---src-pages-odoo-owl-owl-action-service-js": () => import("./../../../src/pages/odoo/owl/owl-action-service.js" /* webpackChunkName: "component---src-pages-odoo-owl-owl-action-service-js" */),
  "component---src-pages-odoo-owl-owl-field-widgets-js": () => import("./../../../src/pages/odoo/owl/owl-field-widgets.js" /* webpackChunkName: "component---src-pages-odoo-owl-owl-field-widgets-js" */),
  "component---src-pages-odoo-owl-owl-todolist-static-server-js": () => import("./../../../src/pages/odoo/owl/owl-todolist-static-server.js" /* webpackChunkName: "component---src-pages-odoo-owl-owl-todolist-static-server-js" */),
  "component---src-pages-odoo-owl-owl-view-inheritance-js": () => import("./../../../src/pages/odoo/owl/owl-view-inheritance.js" /* webpackChunkName: "component---src-pages-odoo-owl-owl-view-inheritance-js" */),
  "component---src-pages-odoo-pos-how-to-inherit-pos-using-owl-js": () => import("./../../../src/pages/odoo/pos/how-to-inherit-pos-using-owl.js" /* webpackChunkName: "component---src-pages-odoo-pos-how-to-inherit-pos-using-owl-js" */),
  "component---src-pages-odoo-pos-index-js": () => import("./../../../src/pages/odoo/pos/index.js" /* webpackChunkName: "component---src-pages-odoo-pos-index-js" */),
  "component---src-pages-odoo-pos-pos-owl-inheritance-module-structure-js": () => import("./../../../src/pages/odoo/pos/pos-owl-inheritance-module-structure.js" /* webpackChunkName: "component---src-pages-odoo-pos-pos-owl-inheritance-module-structure-js" */),
  "component---src-pages-odoo-qweb-index-js": () => import("./../../../src/pages/odoo/qweb/index.js" /* webpackChunkName: "component---src-pages-odoo-qweb-index-js" */),
  "component---src-pages-odoo-qweb-qweb-all-you-need-to-know-js": () => import("./../../../src/pages/odoo/qweb/qweb-all-you-need-to-know.js" /* webpackChunkName: "component---src-pages-odoo-qweb-qweb-all-you-need-to-know-js" */),
  "component---src-pages-odoo-qweb-qweb-templates-python-javascript-js": () => import("./../../../src/pages/odoo/qweb/qweb-templates-python-javascript.js" /* webpackChunkName: "component---src-pages-odoo-qweb-qweb-templates-python-javascript-js" */),
  "component---src-pages-odoo-qweb-templates-js": () => import("./../../../src/pages/odoo/qweb-templates.js" /* webpackChunkName: "component---src-pages-odoo-qweb-templates-js" */),
  "component---src-pages-odoo-theme-create-custom-header-footer-js": () => import("./../../../src/pages/odoo/theme/create-custom-header-footer.js" /* webpackChunkName: "component---src-pages-odoo-theme-create-custom-header-footer-js" */),
  "component---src-pages-odoo-theme-create-dynamic-snippets-js": () => import("./../../../src/pages/odoo/theme/create-dynamic-snippets.js" /* webpackChunkName: "component---src-pages-odoo-theme-create-dynamic-snippets-js" */),
  "component---src-pages-odoo-theme-create-page-menu-mega-menu-js": () => import("./../../../src/pages/odoo/theme/create-page-menu-mega-menu.js" /* webpackChunkName: "component---src-pages-odoo-theme-create-page-menu-mega-menu-js" */),
  "component---src-pages-odoo-theme-create-snippets-with-options-js": () => import("./../../../src/pages/odoo/theme/create-snippets-with-options.js" /* webpackChunkName: "component---src-pages-odoo-theme-create-snippets-with-options-js" */),
  "component---src-pages-odoo-theme-how-to-install-odoo-on-mac-os-js": () => import("./../../../src/pages/odoo/theme/how-to-install-odoo-on-mac-os.js" /* webpackChunkName: "component---src-pages-odoo-theme-how-to-install-odoo-on-mac-os-js" */),
  "component---src-pages-odoo-theme-index-js": () => import("./../../../src/pages/odoo/theme/index.js" /* webpackChunkName: "component---src-pages-odoo-theme-index-js" */),
  "component---src-pages-odoo-theme-website-homepage-using-bootstrap-js": () => import("./../../../src/pages/odoo/theme/website-homepage-using-bootstrap.js" /* webpackChunkName: "component---src-pages-odoo-theme-website-homepage-using-bootstrap-js" */),
  "component---src-pages-odoo-view-type-index-js": () => import("./../../../src/pages/odoo/view-type/index.js" /* webpackChunkName: "component---src-pages-odoo-view-type-index-js" */),
  "component---src-pages-odoo-view-type-map-view-leaflet-owl-framework-js": () => import("./../../../src/pages/odoo/view-type/map-view-leaflet-owl-framework.js" /* webpackChunkName: "component---src-pages-odoo-view-type-map-view-leaflet-owl-framework-js" */),
  "component---src-pages-odoo-view-type-understanding-web-client-architecture-js": () => import("./../../../src/pages/odoo/view-type/understanding-web-client-architecture.js" /* webpackChunkName: "component---src-pages-odoo-view-type-understanding-web-client-architecture-js" */),
  "component---src-pages-odoo-view-type-view-model-arch-parser-js": () => import("./../../../src/pages/odoo/view-type/view-model-arch-parser.js" /* webpackChunkName: "component---src-pages-odoo-view-type-view-model-arch-parser-js" */),
  "component---src-pages-odoo-why-love-odoo-as-developer-js": () => import("./../../../src/pages/odoo/why-love-odoo-as-developer.js" /* webpackChunkName: "component---src-pages-odoo-why-love-odoo-as-developer-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

