import React, { useState, createContext, useEffect } from "react"

export const GlobalContext = createContext({
	youtubeModalSrc:false
})

export const GlobalProvider = ({ children }) => {
	
	const [darkMode, setDarkMode] = useState(typeof window !== 'undefined' && localStorage.getItem("dark") !== null ? localStorage.getItem("dark") : false)
	const [isOpenNewsletter, setOpenNewsletter] = useState(false)
	const [youtubeModalSrc, setYoutubeModalSrc] = useState(false)

	const toggleYoutubeModalSrc = (src) => setYoutubeModalSrc(src)

	const toggleDarkMode = () => setDarkMode(darkMode !== "1" ? "1" : "0")

	const closeNewsletterModal = () => setOpenNewsletter(false)

	const openNewsletterModal = () => setOpenNewsletter(true)

	useEffect(() => {
		if (localStorage.getItem("dark") === null) {
			localStorage.setItem("dark", "1")
			setDarkMode("1")
		}
	}, [])

	useEffect(() => {
		darkMode !== "1" ? document.body.classList.remove("dark") : document.body.classList.add("dark")
		localStorage.setItem("dark", darkMode)
	}, [darkMode])

	return (
		<GlobalContext.Provider value={{
			darkMode, toggleDarkMode,
			isOpenNewsletter, closeNewsletterModal, openNewsletterModal,
			youtubeModalSrc, toggleYoutubeModalSrc,
		}}>
			{children}
		</GlobalContext.Provider>
	)
}
