import * as React from "react"
import { GlobalProvider } from "./context/GlobalContext"
import { Script } from "gatsby"

const WrapRootElement = ({ children }) => (
  <GlobalProvider>
    {children}
    <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7479669064967546" crossorigin="anonymous"></Script>

    </GlobalProvider>
)

export default WrapRootElement
